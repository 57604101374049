import "core-js/modules/es.array.push.js";
import { addTeacher } from "@/api/api";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
        code: '',
        username: ''
      },
      content: ['']
    };
  },
  mounted() {},
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {
      //清空表单
      if (row) {
        this.Info = JSON.parse(row);
      } else {
        this.Info = {};
      }
    },
    changeDic(index, type) {
      if (type == 'add') {
        this.content.push('');
      } else if (type == 'reced') {
        this.content.splice(index, 1);
      }
    },
    subData() {
      //提交
      if (!this.Info.username || !this.Info.code) {
        this.$root.ElMessage.error('学号和用户名不能为空！');
        return false;
      }
      let method = 'post';
      if (this.Info.id) {
        method = 'put';
      }
      addTeacher(this.Info, method).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.message
          });
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    handleAvatarSuccess(e) {
      //图片上传成功后
      this.Info.businessLicenseImg = e.data;
    }
  }
};